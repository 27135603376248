<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-row>
      <b-col
        cols="12"
        sm="6"
      >
        <router-link :to="{ name: 'agent.pending-data' }">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ pending_count }} <span class="fontsize-sm text-danger">{{ pending_over_due_count }}</span></h2>
                  <p class="fontsize-sm m-0 text-success">
                    Pending Raw Data
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                  <i class="ri-inbox-fill" /></div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ pending_campaign_count }}</h2>
                <p class="fontsize-sm m-0 text-success">
                  Pending Campaign Data
                </p>
                <b-button
                  v-if="pending_campaign_count > 0"
                  class="btn-sm mt-1"
                  @click="onConfirmReleaseCampaign"
                >
                  Release <i class="ri-quill-pen-line" />
                </b-button>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" />
              </div>

            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ call_attempt_count }}</h2>
                <p class="fontsize-sm m-0 text-success">
                  Today's Call Attempt
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ connected_count }}</h2>
                <p class="fontsize-sm m-0 text-success">
                  Today's Connected Call
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ successful_count }}</h2>
                <p class="fontsize-sm m-0 text-success">
                  Today's Successful Call
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ unsuccessful_count }}</h2>
                <p class="fontsize-sm m-0 text-success">
                  Today's Unsuccessful Call
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ sales_opportunity_count }}</h2>
                <p class="fontsize-sm m-0 text-success">
                  {{ sales_opportunity_label }}
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AgentDashboardService } from '@/services'

export default {
  name: 'AgentDashboard',

  middleware: ['auth', 'agent'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      pending_count: 0,
      pending_over_due_count: 0,
      pending_campaign_count: 0,
      call_attempt_count: 0,
      connected_count: 0,
      successful_count: 0,
      unsuccessful_count: 0,
      sales_opportunity_label: '',
      sales_opportunity_count: 0
    }
  },

  mounted () {
    core.index()
    this.getStatistics()
  },

  methods: {
    async getStatistics () {
      AgentDashboardService.getStatistics().then(({ data }) => {
        this.pending_count = data.pending_count
        this.pending_over_due_count = data.pending_over_due_count
        this.pending_campaign_count = data.pending_campaign_count
        this.call_attempt_count = data.call_attempt_count
        this.connected_count = data.connected_count
        this.successful_count = data.successful_count
        this.unsuccessful_count = data.unsuccessful_count
        this.sales_opportunity_label = data.sales_opportunity_label
        this.sales_opportunity_count = data.sales_opportunity_count
      })
    },

    async onConfirmReleaseCampaign () {
      const title = 'Are you sure you want  to release this campaign, this cannot be undone ?'
      this.$swal.fire({
        icon: 'question',
        title: 'Question',
        text: title,
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Yes',
        cancelButtonColor: '#FF2929',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.releaseCampaign()
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },

    async releaseCampaign () {
      await AgentDashboardService.releaseCampaign().then(({ data }) => {
        this.$swal
          .fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
          .then(() => {
            this.getStatistics()
          })
      })
    }

  }
}
</script>
